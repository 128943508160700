// src/firebase.js
import { initializeApp } from 'firebase/app';
import { getAnalytics, isSupported } from 'firebase/analytics';
import { getAuth, browserLocalPersistence, indexedDBLocalPersistence } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyC5ylBu1kDtcYcYNi_DsPRkMPTZuk8Lrb8",
  authDomain: "carey-appy.firebaseapp.com",
  projectId: "carey-appy",
  storageBucket: "carey-appy.appspot.com",
  messagingSenderId: "71440605494",
  appId: "1:71440605494:web:104d4b110bf8f875e41397",
  measurementId: "G-4NY6379FBC"
};

const app = initializeApp(firebaseConfig);

let analytics;
isSupported().then((supported) => {
  if (supported) {
    analytics = getAnalytics(app);
  }
});

const auth = getAuth(app);
auth.setPersistence(indexedDBLocalPersistence);

const db = getFirestore(app);
const storage = getStorage(app);

export { app, analytics, auth, db, storage };



// src/screens/PrivacyPolicy.js
import React from 'react';
import { Link } from 'react-router-dom';
import './PrivacyPolicy.css'; // Arquivo CSS para os estilos

const PrivacyPolicy = () => {
  return (
    <div className="privacy-container">
      <Link to="/">
        <img
          src={require('./carey-app.png')}
          className="logo"
          alt="Carey App Logo"
        />
      </Link>
      <h1>Política de Privacidade</h1>
      <p>
        Bem-vindo à Carey Appy. Sua privacidade é importante para nós. Esta política de privacidade explica como coletamos, usamos, divulgamos e protegemos suas informações.
      </p>
      
      <h2>1. Informações que Coletamos</h2>
      <p>
        Coletamos vários tipos de informações em conexão com os serviços que fornecemos, incluindo:
        <ul>
          <li>Informações que você nos fornece diretamente</li>
          <li>Informações que coletamos automaticamente</li>
          <li>Informações de outras fontes</li>
        </ul>
      </p>

      <h2>2. Como Usamos as Informações</h2>
      <p>
        Usamos as informações que coletamos para:
        <ul>
          <li>Fornecer, manter e melhorar nossos serviços</li>
          <li>Personalizar sua experiência</li>
          <li>Comunicarmo-nos com você</li>
          <li>Proteger a segurança de nossos usuários e serviços</li>
        </ul>
      </p>

      <h2>3. Compartilhamento de Informações</h2>
      <p>
        Podemos compartilhar suas informações com terceiros nas seguintes circunstâncias:
        <ul>
          <li>Com seu consentimento</li>
          <li>Para cumprimento de obrigações legais</li>
          <li>Para proteger nossos direitos e segurança</li>
        </ul>
      </p>

      <h2>4. Segurança das Informações</h2>
      <p>
        Implementamos medidas de segurança adequadas para proteger suas informações contra acesso, alteração, divulgação ou destruição não autorizados.
      </p>

      <h2>5. Seus Direitos</h2>
      <p>
        Você tem certos direitos em relação às suas informações, incluindo o direito de acessar, corrigir ou excluir suas informações pessoais.
      </p>

      <h2>6. Alterações nesta Política</h2>
      <p>
        Podemos atualizar esta política de privacidade periodicamente. Notificaremos você sobre quaisquer alterações postando a nova política em nosso site.
      </p>

      <h2>7. Contato</h2>
      <p>
        Se você tiver dúvidas sobre esta Política de Privacidade, entre em contato conosco pelo e-mail <a href="mailto:sac@carey.app.br">sac@carey.app.br</a>.
      </p>
      
      <p>Última atualização: 24/06/2024</p>
    </div>
  );
};

export default PrivacyPolicy;

import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import CaregiverSignUpScreen from './screens/CaregiverSignUpScreen';
import SignUpScreen from './screens/SignUpScreen';
import PreRegistrationCompleteScreen from './screens/PreRegistrationCompleteScreen';
import TermsOfUse from './screens/TermsOfUse';
import PrivacyPolicy from './screens/PrivacyPolicy';
import VerifyEmail from './screens/VerifyEmail'; // Importação do novo componente
import Footer from './screens/Footer';
import './App.css';

function App() {
  return (
    <Router>
      <div className="App">
        <div className="content">
          <Routes>
            <Route path="/" element={<CaregiverSignUpScreen />} />
            <Route path="/signup" element={<SignUpScreen />} />
            <Route path="/pre-registration-complete" element={<PreRegistrationCompleteScreen />} />
            <Route path="/termos-de-uso" element={<TermsOfUse />} />
            <Route path="/politica-de-privacidade" element={<PrivacyPolicy />} />
            <Route path="/verify-email" element={<VerifyEmail />} /> {/* Nova rota adicionada */}
            {/* Adicione outras rotas conforme necessário */}
          </Routes>
        </div>
        <Footer />
      </div>
    </Router>
  );
}

export default App;

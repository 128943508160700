import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelopeOpenText } from '@fortawesome/free-solid-svg-icons';
import './VerifyEmail.css'; // Arquivo CSS para os estilos

const VerifyEmail = () => {
  return (
    <div className="verify-email-container">
      <div className="verify-email-box">
        <FontAwesomeIcon icon={faEnvelopeOpenText} className="verify-email-icon" />
        <h2 className="verify-email-title">Verifique seu E-mail</h2>
        <p className="verify-email-message">
          Por favor, entre no e-mail que você cadastrou e verifique sua conta.
          Lembre-se de que às vezes o e-mail pode estar na caixa de spam ou lixo eletrônico.
        </p>
        <p className="verify-email-check">
          <strong>Não recebeu o e-mail?</strong> <br />
          Verifique seu spam ou lixo eletrônico, ou clique em <a href="/resend-verification">reenviar verificação</a>.
        </p>
      </div>
    </div>
  );
};

export default VerifyEmail;

// src/screens/TermsOfUse.js
import React from 'react';
import { Link } from 'react-router-dom';
import './TermsOfUse.css'; // Arquivo CSS para os estilos

const TermsOfUse = () => {
  return (
    <div className="terms-container">
      <Link to="/">
        <img
          src={require('./carey-app.png')}
          className="logo"
          alt="Carey App Logo"
        />
      </Link>
      <h1>Termos de Uso</h1>
      <p>
        Bem-vindo à Carey Appy. Ao utilizar nosso site, você concorda com os seguintes termos e condições. 
        Se não concordar com qualquer parte destes termos, não utilize nosso site.
      </p>
      
      <h2>Uso do Site</h2>
      <p>
        O uso do site Carey Appy está sujeito às seguintes condições:
        <ul>
          <li>Você deve ter pelo menos 18 anos de idade para usar nosso site.</li>
          <li>Você é responsável por manter a confidencialidade de sua conta e senha.</li>
          <li>Você não deve usar nosso site de forma ilegal ou não autorizada.</li>
        </ul>
      </p>
      
      <h2>Conteúdo Gerado pelo Usuário</h2>
      <p>
        Todos os perfis de membros, anúncios de emprego, inscrições e mensagens são criados por usuários de nosso site e não são gerados ou verificados pela Carey Appy. Você é responsável por garantir que o trabalho ou cuidador escolhido seja apropriado às suas necessidades e esteja em conformidade com as leis aplicáveis.
      </p>
      
      <h2>Limitação de Responsabilidade</h2>
      <p>
        A Carey Appy não emprega nenhum cuidador e não é responsável pela conduta de qualquer usuário do nosso site. Nós não garantimos a precisão, completude ou utilidade de qualquer informação no site e não seremos responsáveis por qualquer perda ou dano causado pelo uso dessas informações.
      </p>
      
      <h2>Alterações aos Termos</h2>
      <p>
        Podemos atualizar estes termos de uso periodicamente. Notificaremos você sobre quaisquer alterações postando os novos termos no site. O uso continuado do site após tais alterações constitui sua aceitação dos novos termos.
      </p>
      
      <h2>Contato</h2>
      <p>
        Se você tiver dúvidas sobre estes Termos de Uso, entre em contato conosco pelo e-mail <a href="mailto:sac@carey.app.br">sac@carey.app.br</a>.
      </p>
      
      <p>Última atualização:  24/06/2024</p>
    </div>
  );
};

export default TermsOfUse;


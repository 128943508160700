import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { createUserWithEmailAndPassword, sendEmailVerification } from 'firebase/auth';
import { auth, db } from '../firebase';
import { doc, setDoc } from 'firebase/firestore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash, faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import './CaregiverSignUpScreen.css'; // Arquivo CSS para os estilos

const CaregiverSignUpScreen = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [acceptTerms, setAcceptTerms] = useState(false);
  const [signupType, setSignupType] = useState('Cuidador'); // Estado para tipo de cadastro
  const [gender, setGender] = useState(''); // Estado para gênero de nascimento
  const navigate = useNavigate();

  const handleSignUp = async () => {
    if (password !== confirmPassword) {
      alert("Passwords do not match");
      return;
    }
    if (!acceptTerms) {
      alert("Você precisa aceitar os Termos de Uso e a Política de Privacidade.");
      return;
    }
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      await setDoc(doc(db, 'cuidadores', user.uid), {
        name: name,
        email: email,
        status: 'conta não verificada',
        tipoDeCadastro: signupType, // Incluindo o tipo de cadastro
        genero: gender, // Incluindo o gênero de nascimento
        tipodeconta: 'Sem Plano' // Incluindo o campo tipodeconta com valor "Sem Plano"
      });

      await sendEmailVerification(user); // Enviar e-mail de verificação
      alert("Cadastro realizado com sucesso! Por favor, verifique seu e-mail.");

      navigate('/verify-email'); // Redirecionar para uma página de instruções de verificação de e-mail
    } catch (error) {
      alert(error.message);
    }
  };

  const handleCancel = () => {
    window.location.href = 'https://carey.app.br/';
  };

  return (
    <div className="signup-container">
      <img
        src={require('./carey-app.png')}
        className="signup-logo"
        alt="Carey App Logo"
      />
      <div className="signup-content-box">
        <div className="signup-form-container">
          <h2 className="signup-title">Cadastro de Cuidador</h2>
          <input
            type="text"
            placeholder="Nome"
            value={name}
            onChange={(e) => setName(e.target.value)}
            className="signup-input"
          />
          <input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="signup-input"
          />
          <div className="signup-password-container">
            <input
              type={showPassword ? "text" : "password"}
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="signup-input"
            />
            <button
              type="button"
              className="signup-password-toggle"
              onClick={() => setShowPassword(!showPassword)}
            >
              <FontAwesomeIcon icon={showPassword ? faEye : faEyeSlash} color="#8BD3CE" />
            </button>
          </div>
          <div className="signup-password-container">
            <input
              type={showConfirmPassword ? "text" : "password"}
              placeholder="Confirm Password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              className="signup-input"
            />
            <button
              type="button"
              className="signup-password-toggle"
              onClick={() => setShowConfirmPassword(!showConfirmPassword)}
            >
              <FontAwesomeIcon icon={showConfirmPassword ? faEye : faEyeSlash} color="#8BD3CE" />
            </button>
          </div>
          <div className="signup-type-container">
            <label htmlFor="signupType">Tipo de Cadastro:</label>
            <select
              id="signupType"
              value={signupType}
              onChange={(e) => setSignupType(e.target.value)}
              className="signup-select"
            >
              <option value="Acompanhante">Acompanhante</option>
              <option value="Cuidador">Cuidador (necessário certificado)</option>
              <option value="Enfermeiro(a)">Enfermeiro(a) (necessário certificado)</option>
              <option value="Motorista" disabled>Motorista (em breve)</option>
              <option value="Comerciante" disabled>Comerciante (em breve)</option>
              <option value="Serviços" disabled>Serviços (em breve)</option>
            </select>
          </div>
          <div className="signup-gender-container">
            <label htmlFor="gender">Gênero de Nascimento:</label>
            <select
              id="gender"
              value={gender}
              onChange={(e) => setGender(e.target.value)}
              className="signup-select"
            >
              <option value="">Selecione</option>
              <option value="Feminino">Feminino</option>
              <option value="Masculino">Masculino</option>
            </select>
          </div>
          <div className="signup-terms-container">
            <input
              type="checkbox"
              id="acceptTerms"
              checked={acceptTerms}
              onChange={() => setAcceptTerms(!acceptTerms)}
            />
            <label htmlFor="acceptTerms">
              Ao clicar em "Cadastrar", você concorda com nossos
              <a href="/termos-de-uso" target="_blank" rel="noopener noreferrer"> Termos de Uso</a> e
              <a href="/politica-de-privacidade" target="_blank" rel="noopener noreferrer"> Política de Privacidade</a>.
            </label>
          </div>
          <button className="signup-button" onClick={handleSignUp}>
            <FontAwesomeIcon icon={faCheck} style={{ marginRight: '8px' }} /> Cadastrar
          </button>
          <button className="signup-cancelButton" onClick={handleCancel}>
            <FontAwesomeIcon icon={faTimes} style={{ marginRight: '8px' }} /> Cancelar Cadastro
          </button>
          
        </div>
<div className="footer-text">
            Carey Appy não emprega nenhum cuidador e não é responsável pela conduta de qualquer usuário do nosso site. Todas as informações em perfis de membros, anúncios de emprego, inscrições e mensagens são criadas por usuários de nosso site e não geradas ou verificadas pela Carey Appy. Você precisa fazer sua própria diligência para garantir que o trabalho ou cuidador escolhido seja apropriado às suas necessidades e esteja em conformidade com as leis aplicáveis.
          </div>

      </div>
    </div>
  );
};

export default CaregiverSignUpScreen;

// src/screens/Footer.js
import React from 'react';
import './Footer.css';

const Footer = () => {
  return (
    <footer className="footer">
      <hr className="footer-line" />
      <p>&copy; 2024 Carey Appy Todos os Direitos Reservados - Desenvolvido por Ligaya</p>
    </footer>
  );
};

export default Footer;

// src/screens/PreRegistrationCompleteScreen.js
import React from 'react';
import './PreRegistrationCompleteScreen.css'; // Arquivo CSS para os estilos

const PreRegistrationCompleteScreen = () => {
  return (
    <div className="container">
      <img
        src={require('./carey-app.png')}
        className="logo"
        alt="Carey App Logo"
      />
      <h2 className="title">Seu pré-cadastro foi finalizado com sucesso!</h2>
      <p className="message">
        Por favor, baixe o aplicativo para finalizar seu cadastro e entrar em contato com vagas e oportunidades incríveis que esperam por você!
      </p>
      <div className="download-buttons">
        <a href="https://play.google.com/store/apps" target="_blank" rel="noopener noreferrer">
          <img src={require('./google.png')} className="download-logo" alt="Google Play Store" />
        </a>
        <a href="https://www.apple.com/app-store/" target="_blank" rel="noopener noreferrer">
          <img src={require('./apple.png')} className="download-logo" alt="Apple App Store" />
        </a>
      </div>
    </div>
  );
};

export default PreRegistrationCompleteScreen;

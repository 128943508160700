// src/screens/SignUpScreen.js
import React from 'react';
import { useNavigate } from 'react-router-dom';
import './SignUpScreen.css'; // Arquivo CSS para os estilos

const SignUpScreen = () => {
  const navigate = useNavigate();

  const handleCaregiverSignUp = () => {
    navigate('/pre-registration-complete');
  };

  return (
    <div className="container">
      <h2 className="title">Como você quer se cadastrar?</h2>
      <button className="button" onClick={handleCaregiverSignUp}>
        Cuidador
      </button>
      <button className="buttonDisabled">
        Motorista (Em breve)
      </button>
      <button className="buttonDisabled">
        Comerciante (Em breve)
      </button>
      <button className="buttonDisabled">
        Profissional (Em breve)
      </button>
    </div>
  );
};

export default SignUpScreen;

